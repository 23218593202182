<template>
  <container class="mb-8 bg-white md:bg-transparent">
    <row v-if="title">
      <column class="w-full">
        <main-title
          v-if="isDesktop"
          type="secondary"
          class="block"
          :has-prefix-icon="hasPrefixIcon"
        >
          {{ title }}
        </main-title>
        <ui-title v-else type="h2" class="block py-4">
          {{ title }}
        </ui-title>
      </column>
    </row>
    <row>
      <column class="w-full">
        <div
          v-for="(infoBlock, index) in infoBlocks"
          :key="index"
          class="mb-5 last:mb-0"
        >
          <info-card
            v-if="infoBlock.description"
            :info="infoBlock"
            :no-truncate="noTruncate"
          />
        </div>
      </column>
    </row>
  </container>
</template>

<script>
import { mapGetters } from 'vuex'
import { UiTitle } from '~/components/atoms'
import { Row, Column, Container } from '~/components/grid'
import { MainTitle, InfoCard } from '~/components/molecules'

export default {
  components: {
    Row,
    Container,
    Column,
    MainTitle,
    InfoCard,
    UiTitle,
  },
  props: {
    title: {
      type: String,
      default: undefined,
    },
    infoBlocks: {
      type: Array,
      default: () => [],
    },
    hasPrefixIcon: {
      type: Boolean,
      default: true,
    },
    noTruncate: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isDesktop() {
      const { $mq } = this
      return $mq === 'lg' || $mq === 'xl'
    },
  },
}
</script>
